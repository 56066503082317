@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  background-color: #000300;
} */



